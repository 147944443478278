import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { queryParamsHelper } from 'utils'
import { deviceQueryHelper } from 'utils/scheduleUtils'

export const reportsApi = createAppApi('reportsQuery', {
  tagTypes: [apiTags.report],
  endpoints: builder => ({
    //TODO rename getReports and getMultipleReport to match the url
    getReports: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/countApiCall`,
        params: { ...params }
      })
    }),
    getMultipleReport: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: '/report',
        params
      })
    }),
    getExposureCount: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/exposureCount`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    }),
    getExposureTime: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/exposureTime`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    }),
    getVerboseData: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/getVerboseData`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    })
  })
})

export const {
  useLazyGetReportsQuery,
  useGetMultipleReportQuery,
  useGetExposureCountQuery,
  useGetExposureTimeQuery,
  useGetVerboseDataQuery
} = reportsApi
