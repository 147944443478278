import React, { memo, useCallback, useState } from 'react'
import { Grid, hexToRgb, withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import classNames from 'classnames'

import DeviceCardScheduleContent from './DeviceCardScheduleContent'
import Tooltip from 'components/Tooltip'
import Spacing from 'components/Containers/Spacing'
import DeviceCard from 'components/Card/DeviceCard'
import { TextWithTooltip } from 'components/Typography'
import MaterialPopup from 'components/Popup/MaterialPopup'
import useTimeout from 'hooks/useTimeout'
import { getDarkenColorFromRgb } from 'utils/color'
import { shadeColor } from 'utils'

const styles = ({ palette, colors, type, spacing }) => {
  return {
    rowWrapper: {
      position: 'relative',
      padding: spacing(1),
      paddingLeft: 10,
      height: 48,
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'all 0.4s ease-out',
      boxShadow: palette[type].boxShadow,
      background: `linear-gradient(to top, ${shadeColor(
        palette[type].card.greyHeader.background,
        20
      )}, ${palette[type].card.greyHeader.background})`,
      '&:hover': {
        borderRadius: '6px',
        '& $hoverElement': {
          opacity: 1
        }
      }
    },
    hoverElement: {
      content: "''",
      position: 'absolute',
      inset: 0,
      borderRadius: '4px',
      background: `linear-gradient(to top, ${shadeColor(
        colors.border[type],
        20
      )}, ${getDarkenColorFromRgb(hexToRgb(colors.border[type]), 0.05)})`,
      opacity: 0,
      transition: 'all 0.4s ease-out'
    },
    cardWrapper: {
      width: 320
    },
    aliasText: {
      lineHeight: 1.1,
      zIndex: 1
    },
    titleText: {
      lineHeight: 1.1,
      zIndex: 1
    },
    selected: {
      transition: 'all 0.4s ease-in-out',
      background: `linear-gradient(45deg, ${shadeColor(colors.accent, 20)}, ${
        colors.accent
      }, ${getDarkenColorFromRgb(
        hexToRgb(shadeColor(colors.accent, 20)),
        0.05
      )}, ${getDarkenColorFromRgb(hexToRgb(colors.accent), 0.15)})`,
      backgroundSize: '250% 100%',
      '&:hover': {
        backgroundPosition: '100% 100%'
      }
    },
    disabled: {
      cursor: 'auto'
    },
    selectedAliasText: {
      color: '#FAF9F6',
      transition: 'all 0.4s ease-out'
    },
    selectedTitleText: {
      color: colors.dark,
      transition: 'all 0.4s ease-out'
    }
  }
}

const ScheduleDeviceRow = ({
  item,
  checkboxValue,
  disabled,
  disabledReason,
  onChange,
  classes
}) => {
  const [setPopupTimeout, clearPopupTimeout] = useTimeout()
  const [popupHidden, setPopupHidden] = useState(true)

  const handleChange = useCallback(() => {
    if (disabled) {
      return
    }
    onChange(item)
  }, [onChange, item, disabled])

  const handleDisplayPopup = useCallback(() => {
    setTimeout(() => {
      const popupContent = document.querySelector('.popup-content')
      const left = _get(popupContent, 'style.left', '0px')
      const leftValue = Number(left.replace('px', ''))
      const top = _get(popupContent, 'style.top', '0px')
      const topValue = Number(top.replace('px', ''))

      if (leftValue < 0 || leftValue > 40) {
        popupContent.style.left = '25px'
      }
      if (topValue < 0) {
        popupContent.style.top = '10px'
      }
    }, 5)
  }, [])

  return (
    <Tooltip
      title={disabledReason || ''}
      disableHoverListener={!disabledReason}
      single
    >
      <div
        onMouseEnter={() => setPopupTimeout(() => setPopupHidden(false), 1000)}
        onMouseLeave={() => {
          clearPopupTimeout()
          setPopupHidden(true)
        }}
        onClick={handleChange}
      >
        <MaterialPopup
          style={popupHidden ? { opacity: 0, display: 'none' } : undefined}
          trigger={
            <Grid
              container
              direction="column"
              justifyContent={item.alias ? 'space-between' : 'center'}
              className={classNames(classes.rowWrapper, {
                [classes.selected]: checkboxValue,
                [classes.disabled]: disabled
              })}
            >
              <div
                className={classNames(classes.hoverElement, {
                  hidden: checkboxValue
                })}
              />
              <TextWithTooltip
                maxWidth="100%"
                color="title.primary"
                weight="bold"
                rootClassName={classNames(classes.aliasText, {
                  [classes.selectedAliasText]: checkboxValue
                })}
              >
                {item.alias}
              </TextWithTooltip>
              <TextWithTooltip
                maxWidth="100%"
                variant="smaller"
                rootClassName={classNames(classes.titleText, {
                  [classes.selectedTitleText]: checkboxValue
                })}
              >
                {item.name}
              </TextWithTooltip>
            </Grid>
          }
          onOpen={handleDisplayPopup}
        >
          <div className={classes.cardWrapper}>
            {!popupHidden && (
              <DeviceCard
                device={item}
                withFooter={false}
                infoIconsPosition="top"
                variant="auto"
                disableHoverEffect
                bottomContentComponent={
                  <Spacing variant={0} paddingHor={2} paddingTop={2}>
                    <DeviceCardScheduleContent device={item} />
                  </Spacing>
                }
              />
            )}
          </div>
        </MaterialPopup>
      </div>
    </Tooltip>
  )
}

export default withStyles(styles)(memo(ScheduleDeviceRow))
