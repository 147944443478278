import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loginSelector, twoFactorSelector } from 'selectors/new/auth'
import { fetchLogin, fetchTwoFactor } from 'actions/new/auth'
import useRouter from 'hooks/router/useRouter'
import useSnackbar from 'hooks/useSnackbar'
import { isPhoneInvalid, parseErrorMessage } from 'utils/errorHandler'
import { storageClear } from 'utils/localStorage'
import { getDashboardUrlByRoleLevel } from 'utils/urlUtils'

export default function useAuth() {
  const dispatch = useDispatch()
  const { isPending, error } = useSelector(loginSelector)
  const { isPending: isTwoFactorPending } = useSelector(twoFactorSelector)
  const { showSnackbar } = useSnackbar()
  const { routerPush, routerState } = useRouter()
  const [isTwoFactor, setIsTwoFactor] = useState(false)
  const [tempLoginData, setTempLoginData] = useState({})

  const onCodeInputRef = useCallback(ref => {
    ref.current.focus()
  }, [])

  const onLoginSuccess = useCallback(
    role => {
      if (routerState.from) {
        routerState.from.state = { ...routerState.from.state, fromLogin: true }
        routerPush(routerState.from)
      } else {
        routerPush({
          pathname: getDashboardUrlByRoleLevel(role),
          state: { fromLogin: true }
        })
      }
    },
    [routerPush, routerState]
  )

  const onLoginFailure = useCallback(
    (error, data) => {
      const { message, code } = error || {}
      if (isPhoneInvalid(code, message)) {
        setTempLoginData(data)
      } else {
        showSnackbar(
          parseErrorMessage(message) || 'Oops.. Something went wrong',
          'error'
        )
        storageClear()
      }
    },
    [showSnackbar]
  )

  const on2FA = useCallback(data => {
    setTempLoginData(data)
    setIsTwoFactor(true)
  }, [])

  const loginUser = useCallback(
    (username, password, rememberMe, role) => {
      dispatch(
        fetchLogin({
          username,
          password,
          rememberMe,
          role,
          onSuccess: onLoginSuccess,
          onFailure: onLoginFailure,
          on2FA: on2FA
        })
      )
    },
    [dispatch, onLoginSuccess, onLoginFailure, on2FA]
  )

  const onCancel = useCallback(() => {
    setIsTwoFactor(false)
    setTempLoginData({})
  }, [])

  const onValidateCodeFailure = useCallback(
    ({ message }) => {
      showSnackbar(message, 'error')
    },
    [showSnackbar]
  )

  const validateCode = useCallback(
    (code, role) => {
      dispatch(
        fetchTwoFactor({
          code,
          email: tempLoginData.username,
          onFailure: onValidateCodeFailure,
          onSuccess: onLoginSuccess,
          role: role
        })
      )
    },
    [dispatch, tempLoginData, onValidateCodeFailure, onLoginSuccess]
  )

  return useMemo(
    () => ({
      isPending,
      isTwoFactor,
      isTwoFactorPending,
      onCodeInputRef,
      onCancel,
      loginUser,
      validateCode,
      error
    }),
    [
      error,
      isPending,
      isTwoFactor,
      isTwoFactorPending,
      onCodeInputRef,
      onCancel,
      loginUser,
      validateCode
    ]
  )
}
