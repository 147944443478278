import { colors } from '../theme'
import { translateOptions } from 'utils/transformToOptionsUtils'

export const deviceStatus = {
  active: 'Active',
  inactive: 'Inactive',
  dormant: 'Dormant',
  disabled: 'Disabled'
}

export const deviceStatusColorByStatus = {
  [deviceStatus.active]: 'rgba(22, 151, 158, 1)',
  [deviceStatus.inactive]: 'rgba(148, 148, 148, 1)',
  [deviceStatus.dormant]: 'rgba(147, 147, 147, 1)',
  [deviceStatus.disabled]: 'rgba(252, 4, 0, 1)'
}

export const ACTIVE_OUTDATED_STATUS_COLOR = 'rgba(34, 117, 180, 1)'

export const DEVICE_IS_OFFLINE =
  'Device is offline or inactive and unable to initiate reboot sequence immediately. If the device resumes connectivity within the next 5 minutes, the device will be rebooted'
export const ALERT_FAILURE =
  "Device is unreachable and was unable to receive the alert. Please check the device's network connection to ensure communication with the CMS"
export const ALERT_FAILURES =
  "Devices are unreachable and were unable to receive the alert. Please check the device's network connection to ensure communication with the CMS"
export const FETCH_EXCEPTION_ID = -1
export const CUSTOM_LOCATION_VALUE = -1

export const LOCATION_IMAGE_MAX_WIDTH = 800
export const LOCATION_IMAGE_MIN_WIDTH = 400
export const LOCATION_IMAGE_MAX_HEIGHT = 800
export const LOCATION_IMAGE_MIN_HEIGHT = 400
export const LOCATION_IMAGE_MAX_SIZE = 1048576
export const AUTO = 'Auto'

export const VIRTUAL_DEVICE_ALIAS = 'VirtualDevice'
const SAMSUNG_DEVICE_ALIAS = 'Samsung'
const ANDROID_DEVICE_ALIAS = 'Android'

export const VIDEOWALL_SUPPORTING_PROCESSOR = 'Intel N100'

export const defaultCoords = { lat: 38.967795, lng: -77.492357 }

export const customLocationCategoryOption = {
  id: 0,
  title: 'Custom',
  locations: [
    {
      title: 'Custom',
      value: CUSTOM_LOCATION_VALUE,
      id: CUSTOM_LOCATION_VALUE,
      deviceLocationImage: null,
      category: {
        id: 0,
        title: 'Custom'
      }
    }
  ]
}

export const screenOrientationOptions = t => [
  {
    label: t('Landscape'),
    value: 'Landscape Clockwise'
  },
  {
    label: t('Landscape Flipped'),
    value: 'Landscape Anti-clockwise'
  },
  {
    label: t('Portrait Right'),
    value: 'Portrait Clockwise'
  },
  {
    label: t('Portrait Left'),
    value: 'Portrait Anti-clockwise'
  },
  {
    label: t(AUTO),
    value: AUTO
  }
]

export const portraitOrientation = 'portrait'

export const primaryInputOptions = t => [
  {
    label: t('Video (HDMI) Input'),
    value: 'VideoInput'
  },
  {
    label: t('Digital Signage'),
    value: 'DigitalSignage'
  }
]

export const logLevelOptions = t => [
  {
    label: t('Fatal'),
    value: 'fatal'
  },
  {
    label: t('Error'),
    value: 'error'
  },
  {
    label: t('Warn'),
    value: 'warn'
  },
  {
    label: t('Info'),
    value: 'info'
  },
  {
    label: t('Debug'),
    value: 'debug'
  },
  {
    label: t('Trace'),
    value: 'trace'
  },
  {
    label: t('Silent'),
    value: 'silent'
  }
]

export const videowallOptions = [
  {
    label: '2',
    value: 2
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '6',
    value: 6
  },
  {
    label: '8',
    value: 8
  },
  {
    label: '9',
    value: 9
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '12',
    value: 12
  }
]
export const locationFilterOptionProps = [
  'city',
  'country',
  'latitude',
  'longitude'
]

export const deviceLogsType = {
  application: 'application',
  playbackNode: 'playbackNode',
  playbackRender: 'playbackRender'
}

export const locationIconMapping = [
  {
    title: 'Banks',
    icon: 'fa-regular fa-building-columns'
  },
  {
    title: 'Corporate',
    icon: 'fa-regular fa-building'
  },
  {
    title: 'Education',
    icon: 'fa-regular fa-school'
  },
  {
    title: 'Entertainment',
    icon: 'fa-regular fa-film'
  },
  {
    title: 'Government',
    icon: 'fa-regular fa-globe'
  },
  {
    title: 'Healthcare',
    icon: 'fa-regular fa-hospital'
  },
  {
    title: 'Hotels',
    icon: 'fa-regular fa-hotel'
  },
  {
    title: 'Manufacturing',
    icon: 'fa-regular fa-industry'
  },
  {
    title: 'Restaurants',
    icon: 'fa-regular fa-utensils'
  },
  {
    title: 'Retail',
    icon: 'fa-regular fa-store'
  },
  {
    title: 'Sports Venues',
    icon: 'fa-regular fa-sportsball'
  },
  {
    title: 'Worship Venues',
    icon: 'fa-regular fa-place-of-worship'
  },
  {
    title: 'Other',
    icon: 'fa-regular fa-box'
  }
]

export const cecSourceOptions = [
  { value: 'hdmi1', label: 'HDMI 1' },
  { value: 'hdmi2', label: 'HDMI 2' },
  { value: 'hdmi3', label: 'HDMI 3' },
  { value: 'hdmi4', label: 'HDMI 4' }
]

export const deviceNotificationExceptions = [
  'DeviceHasMultipleScheduleAssociationException',
  'ConfirmAutoAddDeviceToScheduleException',
  'ModelConflictedDeviceScheduleException',
  'ModelHasScheduleAssociationException',
  'RemoveTokenFromMediaException'
]

export const DEVICE_CONFIGURATIONS = 'Device Configurations'
export const BRIGHT_SIGN = 'BrightSign'
export const MAX_BRIGHT_SIGN_DISPLAYS = 4
export const DEFAULT_NUMBER_OF_DISPLAYS = 1

export const deviceSecurityColors = {
  Active: colors.activeStatus,
  Elapsing: colors.needsAttentionStatus,
  Expired: colors.inactiveStatus,
  Unknown: colors.needsAttentionStatus
}

export const trialClientDeviceTypeOptions = [
  'Android',
  'BrightSign',
  'Raspberry Pi',
  'Samsung'
]

export const screenbrightnessRange = {
  fromBe: {
    desiredStart: 0
  },
  toBe: {
    desiredStart: 1
  }
}

export const defaultScreenBrightness = 5

export const UBUNTU_18_OS_NAME = 'Ubuntu 18'

export const outdatedOSVersions = ['Ubuntu 16', 'BrightSign OS 7']

export const hideNetworkSettingsCardList = [
  VIRTUAL_DEVICE_ALIAS,
  SAMSUNG_DEVICE_ALIAS,
  ANDROID_DEVICE_ALIAS
]

export const DASHBOARD_UPDATE_HISTORY = 'dashboardUpdateHistory'

export const initialDeviceStatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' }
]

export const getDeviceStatusOptions = ({ isSystem, t }) => {
  const options = [
    ...initialDeviceStatusOptions,
    { label: 'Dormant', value: 'Dormant' }
  ]

  if (isSystem) {
    options.push({ label: 'Disabled', value: 'Disabled' })
  }

  return t ? translateOptions(options, t) : options
}

export const updateCustomParameterCacheKey = 'updateCustomParameterCacheKey'
