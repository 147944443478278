const entityConstants = {
  UserLibrary: 'UserLibrary',
  MediaLibrary: 'MediaLibrary',
  DeviceLibrary: 'DeviceLibrary',
  ClientLibrary: 'ClientLibrary',
  PlaylistLibrary: 'PlaylistLibrary',
  VideoTutorials: 'VideoTutorials',
  ScheduleLibrary: 'ScheduleLibrary',
  ScheduleLibraryTimeline: 'ScheduleLibraryTimeline',
  ClientUserLibrary: 'ClientUserLibrary',
  TemplateLibrary: 'TemplateLibrary',
  DeviceTypesLibrary: 'DeviceTypesLibrary',
  AppVersionsLibrary: 'AppVersionsLibrary',
  BrightSignOSVersionsLibrary: 'BrightSignOSVersionsLibrary',
  ClientLicenseLibrary: 'ClientLicenseLibrary',
  LicenseLibrary: 'LicenseLibrary',
  TemplateBackgroundLibrary: 'TemplateBackgroundLibrary',
  ReportLibrary: 'ReportLibrary',
  ContentAppHelpLibrary: 'ContentAppHelpLibrary',
  HTMLContentLibrary: 'HTMLContentLibrary',
  BannerLibrary: 'BannerLibrary',
  NotificationsLibrary: 'NotificationsLibrary',
  EulaTerms: 'EulaTerms',
  PackageLibrary: 'PackageLibrary',
  ClientPackageLibrary: 'ClientPackageLibrary',
  BandwidthPackageLibrary: 'BandwidthPackageLibrary',
  DevicePackageLibrary: 'DevicePackageLibrary',
  MenuMakerLibrary: 'MenuMakerLibrary',
  HelpLibrary: 'HelpLibrary',
  MessageLibrary: 'MessageLibrary',
  TagLibrary: 'TagLibrary',
  RoomLibrary: 'RoomLibrary',
  workplacePoster: 'workplacePoster',
  DeviceNocLibrary: 'DeviceNocLibrary',
  MenuItemLibrary: 'MenuItemLibrary',
  DeviceModelsLibrary: 'DeviceModelsLibrary',
  FeatureLibrary: 'FeatureLibrary',
  ProofOfPlayExposureCount: 'ProofOfPlayExposureCount',
  ProofOfPlayExposureTime: 'ProofOfPlayExposureTime',
  ProofOfPlayPlayerCount: 'ProofOfPlayPlayerCount',
  ProofOfPlayVerboseData: 'ProofOfPlayVerboseData',
  DesignGalleryCategories: 'DesignGalleryCategories',
  ReportsApiUsage: 'ReportsApiUsage',
  ProofOfPlayReportsLibrary: 'ProofOfPlayReportsLibrary'
}

export default entityConstants
