const permissions = {
  customParameters: {
    read: ['client custom parameter show'],
    create: ['client custom parameter store'],
    update: ['client custom parameter update'],
    delete: ['client custom parameter delete'],
    createOrUpdate: [
      'client custom parameter store',
      'client custom parameter update'
    ]
  },
  playlists: {
    read: [
      'client playlist show',
      'system playlist show',
      'enterprise playlist show'
    ],
    create: [
      'client playlist store',
      'system playlist store',
      'enterprise playlist store'
    ],
    update: [
      'client playlist update',
      'system playlist update',
      'enterprise playlist update'
    ],
    delete: [
      'client playlist delete',
      'system playlist delete',
      'enterprise playlist delete'
    ],
    share: ['system playlist share', 'enterprise playlist share'],
    approve: ['client playlist approval'],
    export: [
      'org playlist library record export',
      'system playlist library record export',
      'enterprise playlist library record export'
    ]
  },
  clients: {
    export: [
      'system client library record export',
      'enterprise client library record export'
    ]
  },
  licenses: {
    export: ['system client library record export']
  },
  clientUsers: {
    export: ['system clientUser library record export']
  },
  users: {
    export: [
      'org user library record export',
      'system user library record export',
      'enterprise user library record export'
    ]
  },
  media: {
    export: [
      'org media library record export',
      'system media library record export',
      'enterprise media library record export'
    ]
  },
  devices: {
    export: [
      'org device library record export',
      'system device library record export',
      'enterprise device library record export'
    ]
  },
  templates: {
    export: [
      'org template library record export',
      'system template library record export',
      'enterprise template library record export'
    ]
  },
  reports: {
    export: [
      'org report library record export',
      'system report library record export'
    ]
  },
  appVersions: {
    export: ['system device library record export']
  },
  deviceModels: {
    export: ['system device library record export']
  },
  brightSignOsVersions: {
    export: ['system device library record export']
  }
}

export default permissions
