import { libraryViews } from 'constants/library'
import featureConstants from 'constants/featureConstants'
import { viewTypes } from './libraryConstants'
import { MENU_ITEMS } from './menuMakerConstants'
import { CLIENT_PACKAGE } from './packageConstants'

const {
  Feeds,
  RSSFeed,
  DemoFeeds,
  MediaRSS,
  YouTube,
  Radio,
  CustomWidget,
  News,
  Twitch,
  VideoTutorials
} = featureConstants

const systemPrefix = '/system'

const libraryGroups = {
  [RSSFeed]: `${systemPrefix}/rss-feeds-library`,
  [Feeds]: `${systemPrefix}/feeds`,
  [DemoFeeds]: `${systemPrefix}/demo-feeds`,
  [MediaRSS]: `${systemPrefix}/media-rss`,
  [YouTube]: `${systemPrefix}/youtube`,
  [Radio]: `${systemPrefix}/radio`,
  [CustomWidget]: `${systemPrefix}/custom-widget`,
  [News]: `${systemPrefix}/news`,
  [Twitch]: `${systemPrefix}/twitch`,
  [VideoTutorials]: `${systemPrefix}/tutorial-videos-library`
}

const generateLibraryRoutes = prefix => ({
  root: libraryGroups[prefix],
  tags: libraryGroups[prefix] + '/tags',
  add: `${libraryGroups[prefix]}/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
  edit: `${libraryGroups[prefix]}/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
  list: `${libraryGroups[prefix]}/${libraryViews.list}`,
  advancedList: `${libraryGroups[prefix]}/${libraryViews.advancedList}`,
  goToAdd: (view = libraryViews.list) => `${libraryGroups[prefix]}/${view}/add`,
  goToEdit: (id, view = libraryViews.list) =>
    `${libraryGroups[prefix]}/${view}/${id}/edit`,
  categories: `${libraryGroups[prefix]}/:view(${libraryViews.list}|${libraryViews.advancedList})/categories`,
  goToCategories: (view = libraryViews.list) =>
    `${libraryGroups[prefix]}/${view}/categories`
})

const libraryRoutes = Object.keys(libraryGroups).reduce(
  (accum, prefix) => ({
    ...accum,
    [prefix]: generateLibraryRoutes(prefix)
  }),
  {}
)

const routeByName = {
  ...libraryRoutes,
  users: {
    root: 'users-library',
    list: 'users-library/list',
    advancedList: `users-library/${libraryViews.advancedList}`,
    add: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    goToAdd: (view = 'list') => `users-library/${view}/add`,
    groups: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/group`,
    goToGroups: (view = 'list') => `users-library/${view}/group`,
    edit: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    permissions: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/permissions/:entity`,
    groupsPermission: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/group/:id/permissions/:entity`,
    clone: `users-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/clone`
  },
  contentAppHelp: {
    root: 'content-app-help-pages-library',
    list: 'content-app-help-pages-library/list',
    advancedList: `content-app-help-pages-library/${libraryViews.advancedList}`
  },
  helpPages: {
    root: 'help-pages-library',
    list: `help-pages-library/${libraryViews.list}`,
    advancedList: `help-pages-library/${libraryViews.advancedList}`
  },
  user: {
    userProfile: 'user-profile'
  },
  eula: {
    root: 'eula-terms',
    add: `eula-terms/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    edit: `eula-terms/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    list: `eula-terms/${libraryViews.list}`,
    advancedList: `eula-terms/${libraryViews.advancedList}`,
    goToAdd: (view = libraryViews.list) => `eula-terms/${view}/add`,
    goToEdit: (id, view = libraryViews.list) => `eula-terms/${view}/${id}/edit`
  },

  clientUsers: {
    root: `${systemPrefix}/org-client-users`,
    add: `${systemPrefix}/org-client-users/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    edit: `${systemPrefix}/org-client-users/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    list: `${systemPrefix}/org-client-users/${libraryViews.list}`,
    advancedList: `${systemPrefix}/org-client-users/${libraryViews.advancedList}`,
    goToAdd: (view = libraryViews.list) =>
      `${systemPrefix}/org-client-users/${view}/add`,
    goToEdit: (id, view = libraryViews.list) =>
      `${systemPrefix}/org-client-users/${view}/${id}/edit`,
    eulaClientRoot: `${systemPrefix}/org-client-user`,
    eulaTerms: `${systemPrefix}/org-client-user/:id/eula-terms`
  },
  licenses: {
    root: `${systemPrefix}/licenses`,
    list: `${systemPrefix}/licenses/${libraryViews.list}`,
    advancedList: `${systemPrefix}/licenses/${libraryViews.advancedList}`
  },
  workplacePosters: {
    root: `workplace-posters-library`,
    add: `workplace-posters-library/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    edit: `workplace-posters-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    list: `workplace-posters-library/${libraryViews.list}`,
    advancedList: `workplace-posters-library/${libraryViews.advancedList}`,
    goToAdd: (view = libraryViews.list) =>
      `workplace-posters-library/${view}/add`,
    goToEdit: (id, view = libraryViews.list) =>
      `workplace-posters-library/${view}/${id}/edit`,
    tags: `workplace-posters-library/:view(${libraryViews.list}|${libraryViews.advancedList})/tags`,
    goToTags: (view = libraryViews.list) =>
      `workplace-posters-library/${view}/tags`
  },
  htmlContent: {
    root: `html-contents-library`,
    add: `html-contents-library/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    edit: `html-contents-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    list: `html-contents-library/${libraryViews.list}`,
    advancedList: `html-contents-library/${libraryViews.advancedList}`,
    goToAdd: (view = libraryViews.list) => `html-contents-library/${view}/add`,
    goToEdit: (id, view = libraryViews.list) =>
      `html-contents-library/${view}/${id}/edit`,
    categories: `html-contents-library/:view(${libraryViews.list}|${libraryViews.advancedList})/categories`,
    goToCategories: (view = libraryViews.list) =>
      `html-contents-library/${view}/categories`
  },
  clients: {
    root: 'clients-library',
    edit: `clients-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    list: 'clients-library/list',
    advancedList: `clients-library/${libraryViews.advancedList}`,
    notes: (clientId = ':id', view = libraryViews.list) =>
      `clients-library/${view}/note/${clientId}`,
    licenses: (clientId = ':id', view = libraryViews.list) =>
      `clients-library/${view}/license/${clientId}`,
    billing: (clientId = ':id', view = libraryViews.list) =>
      `clients-library/${view}/billing/${clientId}`,
    add: `clients-library/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    goToAdd: (view = 'list') => `clients-library/${view}/add`,
    groups: `clients-library/:view(${libraryViews.list}|${libraryViews.advancedList})/groups`,
    goToGroups: (view = 'list') => `clients-library/${view}/groups`,
    superAdminSettings: 'clients-library/:id/super-admin-settings',
    WLSuperAdminSettings:
      'clients-library/:id/white-label-super-admin-settings',
    superAdminSettingsWithId: (id, view = libraryViews.list) =>
      `clients-library/${view}/${id}/super-admin-settings`,
    WLSuperAdminSettingsWithId: (id, view = libraryViews.list) =>
      `clients-library/${view}/${id}/white-label-super-admin-settings`,
    editWithId: (id, view = libraryViews.list) =>
      `clients-library/${view}/${id}/edit`
  },
  playlist: {
    root: 'playlist-library',
    listGridAdvanced: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})`,
    create: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/create`,
    goToCreate: (view = 'list') => `playlist-library/${view}/create`,
    groups: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/groups`,
    goToGroups: (view = 'list') => `playlist-library/${view}/groups`,
    interactive: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/interactive-playlist/create`,
    goToInteractive: (view = 'list') =>
      `playlist-library/${view}/interactive-playlist/create`,
    editInteractive: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/interactive-playlist/:playlistId/edit`,
    editInteractivePlaylist: (id, view = 'list') =>
      `playlist-library/${view}/interactive-playlist/${id}/edit`,
    smart: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/smart-playlist`,
    goToSmart: (view = 'list') => `playlist-library/${view}/smart-playlist`,
    editSmart: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/smart-playlist/:playlistId/edit`,
    editSmartPlaylist: (id, view = 'list') =>
      `playlist-library/${view}/smart-playlist/${id}/edit`,
    magicFolders: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/magic-folders-playlist`,
    goToMagicFolders: (view = 'list') =>
      `playlist-library/${view}/magic-folders-playlist`,
    editMagicFolders: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/magic-folders-playlist/:playlistId/edit`,
    editMagicFolderPlaylist: (id, view = 'list') =>
      `playlist-library/${view}/magic-folders-playlist/${id}/edit`,
    edit: `playlist-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/:playlistId/edit`,
    editWithId: (id, view = 'list') => `playlist-library/${view}/${id}/edit`,
    advancedList: `playlist-library/${libraryViews.advancedList}`,
    list: 'playlist-library/list',
    grid: `playlist-library/${viewTypes.GRID}`
  },
  schedule: {
    root: 'schedule-library',
    list: 'schedule-library/list',
    advancedList: `schedule-library/${libraryViews.advancedList}`,
    calendar: 'schedule-library/calendar',
    edit: `schedule-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:scheduleId/edit`,
    editCalendar: 'schedule-library/calendar/:scheduleId/edit',
    groups: 'schedule-library/groups',
    groupsList: 'schedule-library/list/groups',
    groupsGrid: 'schedule-library/calendar/groups',
    groupsAdvancedList: `schedule-library/${libraryViews.advancedList}/groups`,
    publish: 'schedule-library/schedule-publish',
    calendarPublish: 'schedule-library/calendar/schedule-publish',
    advancedPublish: `schedule-library/${libraryViews.advancedList}/schedule-publish`,
    editWithId: (id, view = libraryViews.list) =>
      `schedule-library/${view}/${id}/edit`,
    editCalendarWithId: id => `schedule-library/calendar/${id}/edit`,
    timeline: 'schedule-timeline',
    timelineEdit: 'schedule-timeline/edit/:scheduleId',
    editTimelineWithId: id => `schedule-timeline/edit/${id}`,
    clone: `schedule-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:scheduleId/clone`,
    cloneWithId: (id, view = libraryViews.list) =>
      `schedule-library/${view}/${id}/clone`,
    cloneCalendar: 'schedule-library/calendar/:scheduleId/clone',
    cloneCalendarWithId: id => `schedule-library/calendar/${id}/clone`,
    smartSchedulePublish: 'schedule-library/smart-schedule-publish',
    smartScheduleEdit: `schedule-library/smart-schedule-publish/:view(${libraryViews.list}|${libraryViews.advancedList})/:scheduleId/edit`,
    editSmartScheduleWithId: (id, view = libraryViews.list) =>
      `schedule-library/smart-schedule-publish/${view}/${id}/edit`,
    timelineSmartEdit: 'schedule-timeline/edit/:scheduleId/smart',
    editTimelineSmartWithId: id => `schedule-timeline/edit/${id}/smart`,
    cloneSmart: `schedule-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:scheduleId/clone/smart`,
    cloneSmartWithId: (id, view = libraryViews.list) =>
      `schedule-library/${view}/${id}/clone/smart`,
    editSmartCalendar: 'schedule-library/calendar/:scheduleId/edit/smart',
    editSmartCalendarWithId: id => `schedule-library/calendar/${id}/edit/smart`
  },
  template: {
    root: 'template-library',
    list: 'template-library/list',
    grid: 'template-library/grid',
    groups: 'template-library/list/groups',
    groupsGrid: 'template-library/grid/groups',
    groupsAdvancedList: `template-library/${libraryViews.advancedList}/groups`,
    create: 'template-library/list/create-template',
    createGrid: 'template-library/grid/create-template',
    createAdvancedList: `template-library/${libraryViews.advancedList}/create-template`,
    preset: 'template-library/list/create-template/preset',
    presetGrid: 'template-library/grid/create-template/preset',
    edit: 'template-library/list/create-template/:id?',
    editWithId: id => `template-library/list/create-template/${id}`,
    editGrid: 'template-library/grid/create-template/:id?',
    editGridWithId: id => `template-library/grid/create-template/${id}`,
    templateGallery: 'template-library/list/create-template/template-gallery',
    advancedList: `template-library/${libraryViews.advancedList}`,
    editAdvancedList: `template-library/${libraryViews.advancedList}/create-template/:id?`,
    editAdvancedListWithId: id =>
      `template-library/${libraryViews.advancedList}/create-template/${id}`
  },
  templateBackground: {
    root: 'template-background',
    list: 'template-background/list',
    advancedList: `template-background/${libraryViews.advancedList}`,
    grid: 'template-background/grid',
    create: `template-background/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/create-background`,
    goToAdd: (view = 'list') => `template-background/${view}/create-background`,
    edit: `template-background/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})/edit-background/:id`,
    goToEdit: (id, view = 'list') =>
      `template-background/${view}/edit-background/${id}`
  },
  device: {
    root: 'device-library',
    list: 'device-library/list',
    grid: `device-library/${viewTypes.GRID}`,
    location: `device-library/${viewTypes.LOCATION}`,
    toLibrary: (view = 'list') => `device-library/${view}`,
    listGridAdvanced: `device-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})`,
    advancedList: `device-library/${libraryViews.advancedList}`,
    //for Groups modal
    'advanced-list': `device-library/${libraryViews.advancedList}`,
    edit: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/edit/:id`,
    clone: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/clone/:id`,
    cloneGrid: 'device-library/grid/clone/:id',
    cloneLocation: 'device-library/location/clone/:id',
    goToAdd: (view = 'list') => `device-library/${view}/add-device`,
    goToEdit: (id, view = 'list') => `device-library/${view}/edit/${id}`,
    goToClone: (id, view = 'list') => `device-library/${view}/clone/${id}`,
    editGrid: 'device-library/grid/edit/:id',
    editLocation: 'device-library/location/edit/:id',
    goToEditGrid: id => `device-library/grid/edit/${id}`,
    goToCloneGrid: id => `device-library/grid/clone/${id}`,
    goToEditLocation: id => `device-library/location/edit/${id}`,
    goToCloneLocation: id => `device-library/location/clone/${id}`,
    editCloseLink: backTo => `device-library/${backTo}`,
    add: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/add-device`,
    addGrid: 'device-library/grid/add-device',
    addLocation: 'device-library/location/add-device',
    screenPreview: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/screen-previews`,
    toScreenPreview: (view = 'list') =>
      `device-library/${view}/screen-previews`,
    channelsPreview: 'device-library/grid/channel-previews',
    groups: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/groups`,
    toGroups: (view = 'list') => `device-library/${view}/groups`,
    groupsList: 'device-library/list/groups',
    groupsGrid: 'device-library/grid/groups',
    groupsLocation: 'device-library/location/groups',
    note: 'device-library/(list|grid|location)/note/:id',
    goToNote: (id, view = 'list') => `device-library/${view}/note/${id}`,
    goToHealth: (id, view = 'list') =>
      `device-library/${view}/device-health/${id}`,
    health: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/device-health/:id`,
    healthGrid: 'device-library/grid/device-health/:id',
    healthLocation: 'device-library/location/device-health/:id',
    healthCloseLink: backTo => `device-library/${backTo}`,
    alerts: {
      set: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/set-alerts`,
      match: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/set-alerts/:id`,
      getByName: (name, view = 'list') =>
        `device-library/${view}/set-alerts/${name}`
    },
    viewerMetrics: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/viewer-metrics/:id`,
    viewerMetricsGrid: 'device-library/grid/viewer-metrics/:id',
    viewerMetricsLocation: 'device-library/location/viewer-metrics/:id',
    goToViewerMetrics: (id, view) =>
      `device-library/${view}/viewer-metrics/${id}`,
    viewerMetricsCloseLink: backTo => `device-library/${backTo}`,
    goToIpProviders: (view = 'list') => `device-library/${view}/ip-providers`,
    ipProviders: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/ip-providers`,
    ipProvidersGrid: 'device-library/grid/ip-providers'
  },
  deviceNOC: {
    root: 'device-noc',
    overview: 'device-noc/overview',
    preview: 'device-noc/preview',
    health: 'device-noc/health',
    diagram: 'device-noc/diagram',
    uptime: 'device-noc/uptime',
    alertAlarm:
      'device-noc/:viewType(overview|preview|health|diagram|uptime)/alert-alarm',
    goToAlertAlarm: viewType => `device-noc/${viewType}/alert-alarm`
  },
  deviceTypes: {
    root: 'device-types',
    list: 'device-types/list',
    grid: 'device-types/grid',
    add: 'device-types/list/add-type',
    edit: 'device-types/list/edit/:id',
    goToEdit: id => `device-types/list/edit/${id}`,
    editCloseLink: backTo => `device-types/${backTo}`,
    deviceConfigurations: 'device-types/list/device-configurations'
  },
  deviceModels: {
    root: 'device-models',
    list: 'device-models/list',
    grid: 'device-models/grid',
    advancedList: `device-models/${libraryViews.advancedList}`,
    listGridAdvanced: `device-models/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})`,
    // add: 'device-models/list/add',
    edit: 'device-models/:view/edit/:id',
    goToEdit: (id, view = 'list') => `device-models/${view}/edit/${id}`,
    goToAdd: (view = 'list') => `device-models/${view}/add`,
    editCloseLink: backTo => `device-models/${backTo}`
  },
  designGallery: {
    root: 'design-gallery',
    addDesign: 'design-gallery/design',
    list: 'design-gallery/list',
    edit: 'design-gallery/design/:id',
    goToEdit: id => `design-gallery/design/${id}`
  },
  fontLibrary: {
    root: 'font-library',
    add: 'font-library/add-font'
  },
  appVersions: {
    root: 'application-library',
    list: 'application-library/list',
    advancedList: `application-library/${libraryViews.advancedList}`,
    add: `application-library/:view(list|${libraryViews.advancedList})/add`,
    goToAdd: (view = 'list') => `application-library/${view}/add`,
    edit: `application-library/:view(list|${libraryViews.advancedList})/edit/:id`,
    goToEdit: (id, view = 'list') => `application-library/${view}/edit/${id}`,
    editCloseLink: backTo => `application-library/${backTo}`
  },
  brightSignOsVersions: {
    root: 'os-firmware-library',
    list: 'os-firmware-library/list',
    advancedList: `os-firmware-library/${libraryViews.advancedList}`,
    add: `os-firmware-library/:view(list|${libraryViews.advancedList})/add`,
    edit: `os-firmware-library/:view(list|${libraryViews.advancedList})/edit/:id`,
    goToAdd: (view = 'list') => `os-firmware-library/${view}/add`,
    goToEdit: (id, view = 'list') => `os-firmware-library/${view}/edit/${id}`,
    editCloseLink: backTo => `os-firmware-library/${backTo}`
  },
  clientLicenses: {
    root: 'client-licenses',
    list: `client-licenses/${libraryViews.list}`,
    advancedList: `client-licenses/${libraryViews.advancedList}`,
    add: `client-licenses/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    goToAdd: (view = libraryViews.list) => `client-licenses/${view}/add`,
    edit: `client-licenses/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    goToEdit: (id, view = libraryViews.list) =>
      `client-licenses/${view}/${id}/edit`
  },
  media: {
    root: 'media-library',
    list: 'media-library/list',
    advancedList: `media-library/${libraryViews.advancedList}`,
    grid: `media-library/${viewTypes.GRID}`,
    groups: `media-library/:view(${libraryViews.grid}|${libraryViews.list}|${libraryViews.advancedList})/groups`,
    goToCreate: (view = 'list') =>
      `media-library/${view}/media/add/general/file`,
    goTofavorite: (view = 'list') => `media-library/${view}/media/add/favorite`
  },
  tutorialVideos: {
    root: 'tutorial-videos',
    create: 'tutorial-videos-library/media/add',
    edit: 'tutorial-videos-library/media/edit:id'
  },
  room: {
    root: 'room-library',
    create: 'room-library/add',
    edit: 'room-library/edit/:id',
    goToEdit: id => `room-library/edit/${id}`
  },
  dashboard: {
    root: `${systemPrefix}/dashboard`
  },
  tag: {
    root: 'tags-library'
  },
  menuMaker: {
    root: 'menu-maker-library',
    addDesign: 'menu-maker-library/design',
    editDesign: 'menu-maker-library/design/:id/edit',
    goToEditDesign: id => `menu-maker-library/design/${id}/edit`,
    editPreset: 'menu-maker-library/design/preset/:presetId',
    goToEditPreset: id => `menu-maker-library/design/preset/${id}`,
    viewDesign: 'menu-maker-library/design/:id/',
    goToViewDesign: id => `menu-maker-library/design/${id}`,
    list: `menu-maker-library/${libraryViews.list}`,
    advancedList: `menu-maker-library/${libraryViews.advancedList}`,
    add: `menu-maker-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:variant/add`,
    goToAdd: (view = 'list', variant = MENU_ITEMS) =>
      `menu-maker-library/${view}/${variant}/add`,
    edit: `menu-maker-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:variant/:id/edit`,
    goToEdit: (id, variant = MENU_ITEMS, view = libraryViews.list) =>
      `menu-maker-library/${view}/${variant}/${id}/edit`,
    integrations: `menu-maker-library/:view(${libraryViews.list}|${libraryViews.advancedList})/integrations`,
    goToIntegrations: (view = 'list') =>
      `menu-maker-library/${view}/integrations`,
    integrationProvider: `menu-maker-library/:view(${libraryViews.list}|${libraryViews.advancedList})/integrations/:provider`,
    goToProvider: (view = 'list', provider) =>
      `menu-maker-library/${view}/integrations/${provider}`
  },
  report: {
    root: 'report-library',
    list: 'report-library/list',
    advancedList: `report-library/${libraryViews.advancedList}`,
    create: `report-library/:view(list|${libraryViews.advancedList})/create`,
    goToCreate: (view = 'list') => `report-library/${view}/create`,
    edit: `report-library/:view(list|${libraryViews.advancedList})/edit/:id`,
    goToEdit: (id, view = 'list') => `report-library/${view}/edit/${id}`,
    generate: `report-library/:view(list|${libraryViews.advancedList})/generate/:id?`,
    goToGenerate: (id, view = 'list') =>
      `report-library/${view}/generate/${id ? id : ''}`,
    download: 'report/:reportId/file/:fileId'
  },
  notifications: {
    root: 'notifications-library',
    editCloseLink: backTo => `notifications-library/${backTo}`,
    createEmailTemplate: 'notifications-library/:mode/email-template',
    editCloneEmailTemplate: 'notifications-library/email-template/:mode/:id',
    goToEditEmailTemplate: id =>
      `notifications-library/email-template/edit/${id}`,
    goToCloneEmailTemplate: id =>
      `notifications-library/email-template/clone/${id}`,
    list: `notifications-library/${libraryViews.list}`,
    advancedList: `notifications-library/${libraryViews.advancedList}`,
    add: `notifications-library/:view(${libraryViews.list}|${libraryViews.advancedList})/add`,
    edit: `notifications-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    goToAdd: (view = libraryViews.list) => `notifications-library/${view}/add`,
    goToEdit: (id, view = libraryViews.list) =>
      `notifications-library/${view}/${id}/edit`,
    goToAddEmailTemplate: (view = libraryViews.list) =>
      `notifications-library/${view}/email-template`
  },
  reportApiUsage: {
    root: 'reports-api-usage'
  },
  thirdPartyReport: {
    root: 'third-party-reports'
  },
  proofOfPlayReports: {
    root: 'proof-of-play-reports',
    list: `proof-of-play-reports/${libraryViews.list}`,
    advancedList: `proof-of-play-reports/${libraryViews.advancedList}`
  },
  contentApprove: {
    root: '/content-approve'
  },
  signIn: {
    root: '/sign-in'
  },
  reseller: {
    client: '/reseller/clients-library',
    clientUser: '/reseller/client-users-library',
    user: '/reseller/users-library'
  },
  public: {
    root: `/public/:token`,
    deviceNoc: {
      root: `device-noc`,
      overview: 'device-noc/overview',
      preview: 'device-noc/preview',
      health: 'device-noc/health',
      diagram: 'device-noc/diagram',
      uptime: 'device-noc/uptime'
    }
  },
  features: {
    root: 'feature-management',
    list: `feature-management/${libraryViews.list}`,
    advancedList: `feature-management/${libraryViews.advancedList}`,
    edit: `feature-management/:view(${libraryViews.list}|${libraryViews.advancedList})/:id/edit`,
    goToEdit: (id, view = libraryViews.list) =>
      `feature-management/${view}/${id}/edit`
  },
  designGalleryCategories: {
    root: 'media-library/:view/design-gallery-categories',
    goToRoot: view => `media-library/${view}/design-gallery-categories`
  },
  packageLibrary: {
    root: `${systemPrefix}/packages-library`,
    list: `${systemPrefix}/packages-library/${libraryViews.list}`,
    advancedList: `${systemPrefix}/packages-library/${libraryViews.advancedList}`,
    add: `${systemPrefix}/packages-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:variant/add`,
    goToAdd: (view = 'list', variant = CLIENT_PACKAGE) =>
      `${systemPrefix}/packages-library/${view}/${variant}/add`,
    edit: `${systemPrefix}/packages-library/:view(${libraryViews.list}|${libraryViews.advancedList})/:variant/:id/edit`,
    goToEdit: (id, variant = CLIENT_PACKAGE, view = libraryViews.list) =>
      `${systemPrefix}/packages-library/${view}/${variant}/${id}/edit`
  },
  timeoutError: '/timeout-error',
  serverError: '/server-error',
  maintenanceError: '/maintenance-error',
  expiredPasswordlessLoginError: '/expired-login-error',
  activateDevice: '/activate-device',
  loginPasswordless: '/login/passwordless',
  rolesPermissions: {
    index:
      '/system/roles-permissions/:level(system|org|enterprise|reseller)/:roleId?',
    toIndex: (level = 'system', roleId) =>
      `/system/roles-permissions/${level}${roleId ? `/${roleId}` : ''}`,
    modifyRole: '*/modify-role/:action/:id?',
    toModifyRole: (action = 'add', level = 'system', id, roleId) =>
      `/system/roles-permissions/${level}${
        roleId ? `/${roleId}` : ''
      }/modify-role/${action}${id ? `/${id}` : ''}`
  },
  ipWhitelist: '*/ip-whitelist',
  manageCustomParameters: {
    index: '*/custom-parameters',
    to: '/custom-parameters'
  },
  interactiveWayfind: {
    root: 'interactive-wayfind',
    add: 'interactive-wayfind-editor/add',
    edit: 'interactive-wayfind-editor/edit/:id',
    goToEdit: id => `interactive-wayfind-editor/edit/${id}`
  }
}

export const getPublicRoute = url => {
  return `/public/:token/${url}`
}

export default routeByName
