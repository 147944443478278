import React, { useEffect, useMemo } from 'react'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import NavigationLink from './NavigationLink'

import { getUrlPrefix } from 'utils/index'
import routeByName from 'constants/routes'
import { libraryViews } from 'constants/library'
import featureConstants, {
  DEVICE_NOC_FEATURE,
  featureNames,
  MENU_MAKER_APP_FEATURE,
  SPACES_BOOKING_FEATURE
} from 'constants/featureConstants'
import * as permissionGroups from 'constants/permissionGroups'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import useUserPermissionGroupsByType from 'hooks/api/useUserPermissionGroupsByType'
import { permissionTypes } from 'constants/permissionGroups'
import useFeaturesAvailability from 'hooks/api/useFeaturesAvailability'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import entityConstants from 'constants/entityConstants'
import { libraryViewSelector } from 'selectors/libraryViewSelectors'
import { storageGetItem } from 'utils/localStorage'
import { LIBRARY_VIEW_SETTINGS } from 'constants/localStorage'
import { setLibraryViewSettings } from 'actions/settingsActions'
import { getLibraryRoute } from 'utils/libraryUtils'
import useUserDetails from 'hooks/useUserDetails'
import useSpaceBookingPermissions from 'hooks/useSpaceBookingPermissions'
import useProofOfPlaySettings from 'hooks/useProofOfPlaySettings'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'
import { viewTypes } from 'constants/libraryConstants'
import { SIGNATURE_CARE } from 'constants/clientsConstants'

const {
  Feeds,
  RSSFeed,
  MediaRSS,
  YouTube,
  Radio,
  News,
  CustomWidget,
  Twitch
} = featureConstants

const styles = () => ({
  navigation: {
    display: 'flex',
    alignItems: 'stretch'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5
  }
})

const hasRenderItem = menuItems =>
  menuItems.some(item => !item.hasOwnProperty('render') || item.render)

const countRenderCols = menuItems =>
  menuItems.filter(item => !item.hasOwnProperty('render') || item.render)
    .length > 1
    ? 2
    : 1

const Navigation = ({ t, classes, location, theme, disabled }) => {
  const dispatch = useDispatch()

  const viewSettings = useSelector(libraryViewSelector)

  const [
    showInteractiveContent,
    showSmartPlaylist,
    showDeviceNoc,
    showMenuMaker,
    showTemplateGallery,
    showProofOfPlay,
    showMagicFolder,
    showWayfinding,
    showSmartSchedule
  ] = useFeaturesAvailability(
    featureNames.InteractiveContent,
    featureNames.SmartPlaylist,
    featureNames.DeviceNoc,
    featureNames.MenuMaker,
    featureNames.TemplateGallery,
    featureNames.ProofOfPlay,
    featureNames.MagicFolder,
    featureNames.Wayfinding,
    featureNames.SmartSchedule
  )

  const role = useUserRole()
  const { client, serviceLevel } = useUserDetails()
  const { proofOfPlayOptions } = useProofOfPlaySettings({ client, role })

  //TODO fix for all cases
  const devicesPreviewRoute = useMemo(
    () =>
      location.pathname.split('/').includes('grid')
        ? getUrlPrefix(routeByName.device.toScreenPreview('grid'))
        : getUrlPrefix(routeByName.device.toScreenPreview('list')),
    [location]
  )

  const appVersionsLibraryRootRoute = useMemo(
    () =>
      viewSettings[entityConstants.AppVersionsLibrary] ===
      libraryViews.advancedList
        ? getUrlPrefix(routeByName.appVersions.advancedList)
        : getUrlPrefix(routeByName.appVersions.list),
    [viewSettings]
  )

  const mediaLibraryRoute = useMemo(
    () =>
      getUrlPrefix(
        routeByName.media.goToCreate(
          viewSettings.MediaLibrary === 'grid'
            ? libraryViews.grid
            : viewSettings.MediaLibrary === libraryViews.advancedList
            ? libraryViews.advancedList
            : libraryViews.list
        )
      ),
    [viewSettings.MediaLibrary]
  )

  const deviceLibraryRoute = useMemo(
    () => getUrlPrefix(routeByName.device.goToAdd(viewSettings.DeviceLibrary)),
    [viewSettings.DeviceLibrary]
  )

  const templateLibraryRoute = useMemo(
    () =>
      viewSettings.TemplateLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.template.createAdvancedList)
        : viewSettings.TemplateLibrary === libraryViews.grid
        ? getUrlPrefix(routeByName.template.createGrid)
        : getUrlPrefix(routeByName.template.create),
    [viewSettings.TemplateLibrary]
  )

  const templateBackgroundRoute = useMemo(
    () =>
      viewSettings.TemplateBackgroundLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.templateBackground.advancedList)
        : viewSettings.TemplateBackgroundLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.templateBackground.grid)
        : getUrlPrefix(routeByName.templateBackground.list),
    [viewSettings.TemplateBackgroundLibrary]
  )

  const reportLibraryRoute = useMemo(
    () =>
      viewSettings.ReportLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.report.goToCreate(libraryViews.advancedList))
        : getUrlPrefix(routeByName.report.goToCreate(libraryViews.list)),
    [viewSettings.ReportLibrary]
  )

  const reportLibraryRootRoute = useMemo(
    () =>
      viewSettings.ReportLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.report.advancedList)
        : getUrlPrefix(routeByName.report.list),
    [viewSettings.ReportLibrary]
  )

  const scheduleLibraryRoute = useMemo(
    () =>
      viewSettings.ScheduleLibrary === libraryViews.calendar
        ? getUrlPrefix(routeByName.schedule.calendarPublish)
        : getUrlPrefix(routeByName.schedule.publish),
    [viewSettings.ScheduleLibrary]
  )

  const playlistLibraryRoute = useMemo(
    () =>
      viewSettings.PlaylistLibrary === libraryViews.advancedList
        ? getUrlPrefix(
            routeByName.playlist.goToCreate(libraryViews.advancedList)
          )
        : viewSettings.PlaylistLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.playlist.goToCreate(viewTypes.GRID))
        : getUrlPrefix(routeByName.playlist.goToCreate(libraryViews.list)),
    [viewSettings.PlaylistLibrary]
  )

  const playlistLibraryRootRoute = useMemo(
    () =>
      viewSettings.PlaylistLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.playlist.advancedList)
        : viewSettings.PlaylistLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.playlist.grid)
        : getUrlPrefix(routeByName.playlist.list),
    [viewSettings.PlaylistLibrary]
  )

  const playlistInteractiveRoute = useMemo(
    () =>
      viewSettings.PlaylistLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.playlist.goToInteractive(viewTypes.GRID))
        : viewSettings.PlaylistLibrary === libraryViews.advancedList
        ? getUrlPrefix(
            routeByName.playlist.goToInteractive(libraryViews.advancedList)
          )
        : getUrlPrefix(routeByName.playlist.goToInteractive(libraryViews.list)),
    [viewSettings.PlaylistLibrary]
  )

  const playlistSmartRoute = useMemo(
    () =>
      viewSettings.PlaylistLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.playlist.goToSmart(viewTypes.GRID))
        : viewSettings.PlaylistLibrary === libraryViews.advancedList
        ? getUrlPrefix(
            routeByName.playlist.goToSmart(libraryViews.advancedList)
          )
        : getUrlPrefix(routeByName.playlist.goToSmart(libraryViews.list)),
    [viewSettings.PlaylistLibrary]
  )

  const deviceModelRoute = useMemo(
    () =>
      viewSettings.DeviceModelsLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.deviceModels.advancedList)
        : getUrlPrefix(routeByName.deviceModels.list),
    [viewSettings.DeviceModelsLibrary]
  )

  const playlistMagicFoldersRoute = useMemo(
    () =>
      viewSettings.PlaylistLibrary === viewTypes.GRID
        ? getUrlPrefix(routeByName.playlist.goToMagicFolders(viewTypes.GRID))
        : viewSettings.PlaylistLibrary === libraryViews.advancedList
        ? getUrlPrefix(
            routeByName.playlist.goToMagicFolders(libraryViews.advancedList)
          )
        : getUrlPrefix(
            routeByName.playlist.goToMagicFolders(libraryViews.list)
          ),
    [viewSettings.PlaylistLibrary]
  )

  const readGroups = useUserPermissionGroupsByType(permissionTypes.read)
  const createGroups = useUserPermissionGroupsByType(permissionTypes.create)
  const { getPermissionByName } = usePermissions()

  useEffect(() => {
    const settings = JSON.parse(storageGetItem(LIBRARY_VIEW_SETTINGS))
    if (settings && typeof settings === 'object') {
      dispatch(setLibraryViewSettings(settings))
    }
    // eslint-disable-next-line
  }, [])

  const librariesRoutes = useMemo(
    () => ({
      [entityConstants.DeviceLibrary]:
        viewSettings[entityConstants.DeviceLibrary] === libraryViews.location
          ? getLibraryRoute(
              readGroups.includes(permissionGroups.DEVICE_LOCATION_VIEW),
              routeByName.device.root,
              viewSettings[entityConstants.DeviceLibrary],
              readGroups.includes(permissionGroups.DEVICE)
                ? getUrlPrefix(routeByName.device.list)
                : '#'
            )
          : getLibraryRoute(
              readGroups.includes(permissionGroups.DEVICE),
              routeByName.device.root,
              viewSettings[entityConstants.DeviceLibrary]
            ),
      [entityConstants.TemplateLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.TEMPLATE),
        routeByName.template.root,
        viewSettings[entityConstants.TemplateLibrary]
      ),
      [entityConstants.ScheduleLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.SCHEDULE),
        routeByName.schedule.root,
        viewSettings[entityConstants.ScheduleLibrary]
      ),
      [entityConstants.MediaLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.MEDIA),
        routeByName.media.root,
        viewSettings[entityConstants.MediaLibrary]
      ),
      [entityConstants.PlaylistLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.PLAYLIST),
        routeByName.playlist.root,
        viewSettings[entityConstants.PlaylistLibrary]
      ),
      [entityConstants.ClientLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.CLIENT),
        routeByName.clients.root,
        viewSettings[entityConstants.ClientLibrary]
      ),
      [entityConstants.LicenseLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.DEVICE_REFRESH_LICENSE),
        'licenses',
        viewSettings[entityConstants.LicenseLibrary]
      ),
      [entityConstants.ClientLicenseLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.CLIENT_LICENSE),
        routeByName.clientLicenses.root,
        viewSettings[entityConstants.ClientLicenseLibrary]
      ),
      [entityConstants.ClientUserLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.CLIENT_USER),
        'org-client-users',
        viewSettings[entityConstants.ClientUserLibrary]
      ),
      [entityConstants.ProofOfPlayReportsLibrary]: getLibraryRoute(
        true,
        routeByName.proofOfPlayReports.root,
        viewSettings[entityConstants.ProofOfPlayReportsLibrary]
      ),
      [entityConstants.BrightSignOSVersionsLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.DEVICE_OS_FIRMWARE),
        routeByName.brightSignOsVersions.root,
        viewSettings[entityConstants.BrightSignOSVersionsLibrary]
      ),
      [entityConstants.MenuMakerLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.MENU_MAKER),
        routeByName.menuMaker.root,
        viewSettings[entityConstants.MenuMakerLibrary]
      )
    }),
    [readGroups, viewSettings]
  )

  const playlistMenuItems = useMemo(() => {
    const menuItems = [
      {
        linkTo: playlistLibraryRoute,
        label: t('Create Playlist'),
        description: t(
          'Simple drag-n-drop creator to build playback sequences.'
        ),
        iconClassName: 'fa-regular fa-rectangle-history-circle-plus',
        render: createGroups.includes(permissionGroups.PLAYLIST)
      },
      {
        linkTo: playlistLibraryRootRoute,
        label: t('Playlist Library'),
        description: t('Edit, preview, and manage playlists.'),
        iconClassName: 'fa-regular fa-rectangle-history',
        render: readGroups.includes(permissionGroups.PLAYLIST)
      }
    ]
    if (showSmartPlaylist)
      menuItems.push({
        linkTo: playlistSmartRoute,
        label: t('Smart Playlist'),
        description: t(
          'Tag-based playlist generator for programmatic playback.'
        ),
        iconClassName: 'fa-light fa-lightbulb-on',
        render:
          role.org && createGroups.includes(permissionGroups.SMART_PLAYLIST)
      })
    if (showInteractiveContent)
      menuItems.push({
        linkTo: playlistInteractiveRoute,
        label: t('Interactive Playlist'),
        description: t('Create touch-enabled, playback sequences.'),
        iconClassName: 'fa-sharp fa-regular fa-bullseye-pointer',
        render:
          role.org &&
          createGroups.includes(permissionGroups.INTERACTIVE_PLAYLIST)
      })
    if (showMagicFolder)
      menuItems.push({
        linkTo: playlistMagicFoldersRoute,
        label: t('Magic Folders'),
        description: 'Synced playlist option for cloud-hosted content.',
        iconClassName: 'far fa-folder-download',
        render:
          role.org && createGroups.includes(permissionGroups.ONE_DRIVE_PLAYLIST)
      })

    return menuItems
  }, [
    playlistLibraryRoute,
    t,
    createGroups,
    playlistLibraryRootRoute,
    readGroups,
    showSmartPlaylist,
    playlistSmartRoute,
    role.org,
    showInteractiveContent,
    playlistInteractiveRoute,
    showMagicFolder,
    playlistMagicFoldersRoute
  ])

  const clientMenuItems = useMemo(
    () => [
      {
        linkTo: librariesRoutes[entityConstants.ClientLibrary],
        label: t('Clients menu item'),
        description: t(
          'Create and manage clients accounts & assign features and permissions'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-user-tie',
        render: role.system && readGroups.includes(permissionGroups.CLIENT)
      },
      {
        linkTo: librariesRoutes[entityConstants.ClientLicenseLibrary],
        label: t('Client Licenses'),
        description: t('Create and manage client licenses'),
        iconClassName: 'fa-light fa-key',
        render:
          role.system && readGroups.includes(permissionGroups.CLIENT_LICENSE),
        printSystem: true
      },
      {
        linkTo: librariesRoutes[entityConstants.ClientUserLibrary],
        label: t('Client Users'),
        description: t(
          'Create and manage client users & assign roles and permissions'
        ),
        iconClassName: 'fa-regular fa-user',
        render:
          role.system && readGroups.includes(permissionGroups.CLIENT_USER),
        printSystem: true
      },
      {
        linkTo: librariesRoutes[entityConstants.LicenseLibrary],
        label: t('License Library'),
        description: t('Manage various licenses'),
        iconClassName: 'fa-thin fa-key',
        render:
          role.system &&
          readGroups.includes(permissionGroups.DEVICE_REFRESH_LICENSE),
        printSystem: true,
        fontSize: '22px'
      }
    ],
    [librariesRoutes, readGroups, role.system, t]
  )

  const deviceMenuItems = useMemo(
    () => [
      {
        linkTo: deviceLibraryRoute,
        label: t('Add Device'),
        description: t('Configure devices or screens for use with the CMS.'),
        iconClassName: 'fa-regular fa-circle-plus',
        render:
          role.system &&
          getPermissionByName(permissionNames.SYSTEM_DEVICE_STORE)
      },
      {
        linkTo: librariesRoutes[entityConstants.DeviceLibrary],
        label: t('Device Library'),
        description: t('Manage or monitor devices and their health.'),
        iconClassName: 'fa-regular fa-shelves ',
        render: readGroups.includes(permissionGroups.DEVICE)
      },
      {
        linkTo: `/${role.role}/device-noc/overview`,
        label: t('Device NOC'),
        description: t('Manage or monitor devices and their health.'),
        iconClassName: 'fa-regular fa-shelves',
        render:
          isFeatureAvailable(DEVICE_NOC_FEATURE) &&
          role.org &&
          showDeviceNoc &&
          readGroups.includes(permissionGroups.DEVICE)
      },
      {
        linkTo: devicesPreviewRoute,
        label: t('Device Previews'),
        description: t('Monitor what is playing on each screen, remotely.'),
        iconClassName: 'fa-regular fa-images',
        render: readGroups.includes(permissionGroups.DEVICE)
      },
      {
        linkTo: deviceModelRoute,
        label: t('Device Models'),
        description: t('Define supported devices models and their features'),
        printSystem: true,
        iconClassName: 'fa-regular fa-shelves',
        //TODO change to device model
        render: role.system && readGroups.includes(permissionGroups.DEVICE_TYPE)
      },
      {
        linkTo: appVersionsLibraryRootRoute,
        label: t('Application Versions'),
        description: t(
          'Manage different application versions for each device type'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-server',
        render: role.system && readGroups.includes(permissionGroups.APP_VERSION)
      },
      {
        linkTo: librariesRoutes[entityConstants.BrightSignOSVersionsLibrary],
        label: t('BrightSign OS Versions'),
        description: t(
          'Manage approved OS versions for BrightSign device updates.'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-file-zipper',
        render: role.system
      },
      {
        render: false, // Hide the page until further notice
        linkTo: `/${role.role}/channels-library`,
        label: t('Channels'),
        iconClassName: 'fa-light fa-list'
      }
    ],
    [
      deviceLibraryRoute,
      t,
      role.system,
      role.role,
      role.org,
      getPermissionByName,
      librariesRoutes,
      readGroups,
      showDeviceNoc,
      devicesPreviewRoute,
      appVersionsLibraryRootRoute,
      deviceModelRoute
    ]
  )

  const {
    hasPermission: hasSpaceBookingPermission
  } = useSpaceBookingPermissions()

  const mediaMenuItems = useMemo(
    () => [
      {
        linkTo: mediaLibraryRoute,
        label: t('Add Media'),
        description: t('Upload media and configure dynamic media content.'),
        iconClassName: 'fa-regular fa-circle-plus',
        render:
          createGroups.includes(permissionGroups.MEDIA) &&
          readGroups.includes(permissionGroups.MEDIA)
      },
      {
        linkTo: librariesRoutes[entityConstants.MediaLibrary],
        label: t('Media Library'),
        description: t('Ready-for-use media, previews and bulk edits.'),
        iconClassName: 'fa-regular fa-shelves',
        render: readGroups.includes(permissionGroups.MEDIA)
      },
      {
        linkTo: getUrlPrefix(routeByName.designGallery.list),
        label: t('Design Gallery'),
        description: t('Library of signage templates with design editor.'),
        iconClassName: 'fa-regular fa-palette',
        render: createGroups.includes(permissionGroups.DESIGN_GALLERY)
      },
      {
        linkTo: getUrlPrefix(routeByName.interactiveWayfind.root),
        label: t('Interactive Wayfinding'),
        description: t(
          'Interact with touchscreen maps to view floor plans and routes'
        ),
        iconClassName: 'fa-regular fa-signs-post',
        render: showWayfinding
      },
      {
        linkTo: getUrlPrefix(routeByName.fontLibrary.root),
        label: t('Font Library'),
        description: t('Repository of 900+ open source & custom fonts.'),
        iconClassName: 'fa-solid fa-square-a',
        render: readGroups.includes(permissionGroups.MEDIA_FONT)
      },
      {
        linkTo: isFeatureAvailable(MENU_MAKER_APP_FEATURE)
          ? role.system || role.enterprise
            ? getUrlPrefix(routeByName.menuMaker.addDesign)
            : librariesRoutes[entityConstants.MenuMakerLibrary]
          : '#',
        label:
          t('Menu Maker') +
          (isFeatureAvailable(MENU_MAKER_APP_FEATURE)
            ? ''
            : ` (${t('Coming Soon')})`),
        description: t('Dynamically-Integrated digital menu board creator.'),
        render:
          (role.system || showMenuMaker) &&
          readGroups.includes(permissionGroups.MENU_MAKER),
        iconClassName: 'fa-sharp fa-regular fa-fork-knife'
      },
      {
        linkTo: getUrlPrefix(routeByName.room.root),
        label: t('Spaces'),
        description: t('Add, edit and manage space booking rooms.'),
        iconClassName: 'fa fa-screen-users',
        render:
          isFeatureAvailable(SPACES_BOOKING_FEATURE) &&
          hasSpaceBookingPermission
      },
      {
        linkTo:
          viewSettings.MediaContentSource[RSSFeed] === libraryViews.advancedList
            ? routeByName[RSSFeed].advancedList
            : routeByName[RSSFeed].list,
        label: t('RSS Feeds'),
        description: t('Define content sources for RSS feed content app'),
        printSystem: true,
        iconClassName: 'fa-regular fa-rss',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[Feeds] === libraryViews.advancedList
            ? routeByName[Feeds].advancedList
            : routeByName[Feeds].list,
        label: t('Feeds'),
        description: t(
          'Define content sources for feed (Screenfeed) content app'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-rss',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[News] === libraryViews.advancedList
            ? routeByName[News].advancedList
            : routeByName[News].list,
        label: t('News'),
        description: t('Define content sources for News content app'),
        printSystem: true,
        iconClassName: 'fa-regular fa-newspaper',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[Twitch] === libraryViews.advancedList
            ? routeByName[Twitch].advancedList
            : routeByName[Twitch].list,
        label: t('Twitch'),
        description: t('Define content sources for Twitch content app'),
        printSystem: true,
        iconClassName: 'fa-regular fa-clapperboard-play',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.HTMLContentLibrary === libraryViews.advancedList
            ? getUrlPrefix(routeByName.htmlContent.advancedList)
            : getUrlPrefix(routeByName.htmlContent.list),
        label: t('HTML Content'),
        description: t('Define content sources for HTML content app'),
        printSystem: true,
        iconClassName: 'fa-solid fa-code',
        render:
          role.system && readGroups.includes(permissionGroups.HTML_CONTENT)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[MediaRSS] ===
          libraryViews.advancedList
            ? routeByName[MediaRSS].advancedList
            : routeByName[MediaRSS].list,
        label: t('Media RSS'),
        description: t('Define content sources for MediaRSS content app'),
        printSystem: true,
        iconClassName: 'fa-regular fa-square-rss',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.workplacePoster === libraryViews.advancedList
            ? getUrlPrefix(routeByName.workplacePosters.advancedList)
            : getUrlPrefix(routeByName.workplacePosters.list),
        label: t('Workplace Posters'),
        description: t(
          'Define content sources for Workplace Posters content app'
        ),
        printSystem: true,
        iconClassName: 'fa-sharp fa-regular fa-images',
        render: role.system && readGroups.includes(permissionGroups.POSTER)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[YouTube] === libraryViews.advancedList
            ? routeByName[YouTube].advancedList
            : routeByName[YouTube].list,
        label: t('YouTube'),
        description: t('Define content sources for Youtube content app'),
        printSystem: true,
        iconClassName: 'fa fa-youtube-play',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[Radio] === libraryViews.advancedList
            ? routeByName[Radio].advancedList
            : routeByName[Radio].list,
        label: t('Radio'),
        description: t('Define content sources for Radio Stations content app'),
        printSystem: true,
        iconClassName: 'fa-regular fa-boombox',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      },
      {
        linkTo:
          viewSettings.MediaContentSource[CustomWidget] ===
          libraryViews.advancedList
            ? routeByName[CustomWidget].advancedList
            : routeByName[CustomWidget].list,
        label: t('Custom Widgets'),
        description: t('Define custom widgets for specific client'),
        printSystem: true,
        iconClassName: 'fa-regular fa-palette',
        render:
          role.system &&
          readGroups.includes(permissionGroups.MEDIA_CONTENT_SOURCE)
      }
    ],
    [
      createGroups,
      readGroups,
      role,
      showMenuMaker,
      hasSpaceBookingPermission,
      librariesRoutes,
      t,
      mediaLibraryRoute,
      viewSettings,
      showWayfinding
    ]
  )

  const templateMenuItems = useMemo(
    () => [
      {
        linkTo: templateLibraryRoute,
        label: t('Create Template'),
        description: t('Create zone-based layouts and interactive templates.'),
        iconClassName: 'fa-regular fa-circle-plus',
        render: createGroups.includes(permissionGroups.TEMPLATE)
      },
      {
        linkTo: librariesRoutes[entityConstants.TemplateLibrary],
        label: t('Template Library'),
        description: t(
          'Manage repository of layouts and templates for bulk edits.'
        ),
        iconClassName: 'fa-regular fa-shelves',
        render: readGroups.includes(permissionGroups.TEMPLATE)
      },
      {
        linkTo: templateBackgroundRoute,
        label: t('Template Background'),
        description: t(
          'Create and manage background patterns for template editor'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-shelves',
        render:
          role.system &&
          readGroups.includes(permissionGroups.TEMPLATE_BACKGROUND_PATTERN)
      },
      {
        linkTo: getUrlPrefix(routeByName.template.templateGallery),
        label: t('Template Gallery'),
        description: t('Collection of pre-created Layouts & Templates'),
        iconClassName: 'fa-regular fa-table-layout',
        render:
          role.org &&
          showTemplateGallery &&
          readGroups.includes(permissionGroups.TEMPLATE) &&
          createGroups.includes(permissionGroups.TEMPLATE)
      }
    ],
    [
      readGroups,
      createGroups,
      librariesRoutes,
      role,
      showTemplateGallery,
      t,
      templateLibraryRoute,
      templateBackgroundRoute
    ]
  )

  const publishMenuItems = useMemo(
    () => [
      {
        linkTo: scheduleLibraryRoute,
        label: t('Schedule & Publish'),
        description: t(
          'Calendar-based scheduling & content publishing on screens.'
        ),
        iconClassName: 'fa-regular fa-calendar-clock',
        render: createGroups.includes(permissionGroups.SCHEDULE)
      },
      {
        linkTo: getUrlPrefix(routeByName.schedule.smartSchedulePublish),
        label: t('Smart Schedules'),
        description: t('Tag-based scheduling & content publishing on screens.'),
        iconClassName: 'fa-light fa-lightbulb-on',
        render:
          createGroups.includes(permissionGroups.SCHEDULE) &&
          serviceLevel === SIGNATURE_CARE &&
          showSmartSchedule
      },
      {
        linkTo: librariesRoutes[entityConstants.ScheduleLibrary],
        label: t('Schedule Library'),
        description: t(
          'Library of playback schedules and their allocation to screens.'
        ),
        iconClassName: 'fa-regular fa-shelves',
        render: readGroups.includes(permissionGroups.SCHEDULE)
      },
      {
        linkTo: getUrlPrefix(routeByName.schedule.timeline),
        label: t('Schedule Timeline'),
        description: t(
          'Unique timeline-view of schedules using drag-n-drop interface.'
        ),
        iconClassName: 'fa-light fa-list',
        render: readGroups.includes(permissionGroups.SCHEDULE)
      }
    ],
    [
      t,
      readGroups,
      createGroups,
      librariesRoutes,
      scheduleLibraryRoute,
      serviceLevel,
      showSmartSchedule
    ]
  )

  const reportMenuItems = useMemo(
    () => [
      {
        linkTo: reportLibraryRoute,
        label: t('Create Report'),
        description: t('Create reports and logs of playback on each screen.'),
        iconClassName: 'fa-regular fa-file-pen',
        render: createGroups.includes(permissionGroups.SYSTEM_REPORT)
      },
      {
        linkTo: reportLibraryRootRoute,
        label: t('Reports Library'),
        description: t('Pre-configured and exportable report views.'),
        iconClassName: 'fa-regular fa-file-lines',
        render: readGroups.includes(permissionGroups.SYSTEM_REPORT)
      },
      {
        linkTo: getUrlPrefix(routeByName.thirdPartyReport.root),
        label: t('Third Party Reports page title'),
        description: t('Pre-configured third party report views.'),
        iconClassName: 'icon-folder-document',
        render:
          role.system && readGroups.includes(permissionGroups.SYSTEM_REPORT)
      },
      {
        linkTo: getUrlPrefix(routeByName.reportApiUsage.root),
        label: t('Reports API Usage'),
        description: t('API Usage report views'),
        iconClassName: 'icon-folder-document',
        render: role.system
      },
      {
        linkTo: librariesRoutes[entityConstants.ProofOfPlayReportsLibrary],
        label: t('Proof of Play Reports'),
        description: t('Pre-configured proof of play report views.'),
        iconClassName: 'icon-folder-document',
        render: showProofOfPlay && proofOfPlayOptions
      }
    ],
    [
      reportLibraryRoute,
      t,
      role,
      createGroups,
      reportLibraryRootRoute,
      readGroups,
      showProofOfPlay,
      proofOfPlayOptions,
      librariesRoutes
    ]
  )

  const navigationStructure = useMemo(
    () => [
      {
        url: librariesRoutes[entityConstants.ClientLibrary],
        color: theme.colors.other.color7[theme.type],
        linkIconClassName: 'fa-regular fa-user-tie',
        linkText: t('Clients menu item'),
        render: role.system && hasRenderItem(clientMenuItems),
        cols: countRenderCols(clientMenuItems),
        menuItems: clientMenuItems
      },
      {
        url: librariesRoutes[entityConstants.DeviceLibrary],
        color: theme.colors.other.color1[theme.type],
        linkIconClassName: 'fa-regular fa-desktop',
        linkText: t('Devices menu item'),
        render: hasRenderItem(deviceMenuItems),
        cols: countRenderCols(deviceMenuItems),
        menuItems: deviceMenuItems
      },
      {
        url: librariesRoutes[entityConstants.MediaLibrary],
        color: theme.colors.other.color2[theme.type],
        linkIconClassName: 'icon-files-landscape-video',
        linkText: t('Media menu item'),
        render: hasRenderItem(mediaMenuItems),
        cols: countRenderCols(mediaMenuItems),
        menuItems: mediaMenuItems
      },
      {
        url: librariesRoutes[entityConstants.PlaylistLibrary],
        color: theme.colors.other.color3[theme.type],
        linkIconClassName: 'icon-playlist-2',
        linkText: t('Playlists menu item'),
        render: hasRenderItem(playlistMenuItems),
        cols: countRenderCols(playlistMenuItems),
        menuItems: playlistMenuItems
      },
      {
        url: librariesRoutes[entityConstants.TemplateLibrary],
        color: theme.colors.other.color4[theme.type],
        linkIconClassName: 'fa-light fa-diagram-cells fa-lg',
        linkText: t('Templates menu item'),
        render: hasRenderItem(templateMenuItems),
        cols: countRenderCols(templateMenuItems),
        menuItems: templateMenuItems
      },
      {
        url: librariesRoutes[entityConstants.ScheduleLibrary],
        color: theme.colors.schedule[theme.type],
        linkIconClassName: 'icon-cloud-downloading-2',
        linkText: t('Publish menu item'),
        render: role.org && hasRenderItem(publishMenuItems),
        cols: countRenderCols(publishMenuItems),
        menuItems: publishMenuItems
      },
      // {
      //   url: '/controls',
      //   color: '#ff833d',
      //   linkIconClassName: 'icon-slider-2',
      //   linkText: t('Controls menu item'),
      //   render: role.system,
      //   cols: 2,
      //   menuItems: [
      //     {
      //       linkTo: '',
      //       label: t('Queue Library'),
      //       iconClassName: 'fa-regular fa-shelves'
      //     },
      //     { linkTo: '', label: t('Alert System'), icon: Warning }
      //   ]
      // },
      {
        url: reportLibraryRootRoute,
        color: theme.colors.other.color6[theme.type],
        linkIconClassName: 'fa-light fa-note-sticky',
        linkText: t('Reports menu item'),
        render: role.system && hasRenderItem(reportMenuItems),
        cols: countRenderCols(reportMenuItems),
        menuItems: reportMenuItems
      }
    ],
    [
      theme,
      t,
      role,
      clientMenuItems,
      librariesRoutes,
      deviceMenuItems,
      mediaMenuItems,
      playlistMenuItems,
      templateMenuItems,
      publishMenuItems,
      reportLibraryRootRoute,
      reportMenuItems
    ]
  )

  return (
    <nav
      className={classNames(classes.navigation, {
        [classes.disabled]: disabled
      })}
    >
      {navigationStructure
        .filter(item => (item.hasOwnProperty('render') ? item.render : true))
        .map((item, index) => (
          <NavigationLink key={`${item.linkText}-${index}`} {...item} t={t} />
        ))}
    </nav>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles, { withTheme: true }),
  withRouter
)(Navigation)
